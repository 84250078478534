import {Ref, ref} from "vue";
import {defineStore} from "pinia";
import {LoadingState} from "@/services/contracts/LoadingState";
import QuizService from "@/services/QuizService";
import {IAnswer} from "@/services/contracts/IAnswer";
import {useSelectedQuizStore} from "@/stores/selectedQuiz";
import {logError} from "@/services/support/error";


export const useAnswerListStore = defineStore('answerList', () => {

    const loadingState = ref(LoadingState.LOADING);
    const answerList: Ref<Array<IAnswer>> = ref([]);

    async function fetch() {

        console.debug('Fetching answers...');
        loadingState.value = LoadingState.LOADING;

        const selectedQuizStore = useSelectedQuizStore();

        if (selectedQuizStore.quiz == null) {
            answerList.value = [];
            loadingState.value = LoadingState.ERROR;
            window.location.href = '/';
            return;
        }

        try {
            const response = await QuizService.getAnsweredQuestion(selectedQuizStore.quiz.id);
            answerList.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            answerList.value = [];
            loadingState.value = LoadingState.ERROR;
            logError(`Failed to fetch answers - quiz: ${selectedQuizStore.quiz.id}`, err)
        }
    }

    return {answerList, loadingState, fetch}
})
