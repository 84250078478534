import {createAuth0} from "@auth0/auth0-vue";
import {getEnv} from "@/services/support/runtime";

export const auth0 = createAuth0({
    domain: getEnv('AUTH0_DOMAIN'), //process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: getEnv('AUTH0_CLIENT_ID'), // process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: getEnv('AUTH0_CALLBACK_URL'), //process.env.VUE_APP_AUTH0_CALLBACK_URL,
        audience: getEnv('AUTH0_AUDIENCE'), //process.env.VUE_APP_AUTH0_AUDIENCE,
    },
});