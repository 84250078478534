<script setup lang="ts">
import {useTeamListStore} from "@/stores/teamList";
import {LoadingState} from "@/services/contracts/LoadingState";
import {computed, onMounted} from "vue";
import {MembershipState} from "@/services/contracts/MembershipState";
import MyButton from "@/components/MyButton.vue";
import LoadingError from "@/components/LoadingError.vue";
import {i18n} from "@/services/support/i18n";
import HomeScoreBoard from "@/components/ScoreBoard.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const teamsListStore = useTeamListStore();
const loadingState = computed(() => teamsListStore.loadingState);
const teams = computed(() => teamsListStore.teamState.items.filter(t => t.state == MembershipState.ACCEPTED));

onMounted(() => {
  teamsListStore.fetch();
})
</script>

<template>
  <div class="pb-3">
    <h1>{{ i18n.team }}</h1>

    <LoadingIndicator :show="loadingState == LoadingState.LOADING && teams.length == 0"/>
    <LoadingError v-if="loadingState == LoadingState.ERROR"/>

    <div v-if="loadingState != LoadingState.ERROR">
      <div v-if="teams.length < 1 && loadingState == LoadingState.OK">
        <div class="mb-1">{{ i18n.notATeamMember }}</div>
        <router-link to="/profile#team">
          <MyButton>{{ i18n.joinTeam }}</MyButton>
        </router-link>
      </div>

      <div v-else v-for="team in teams" :key="team.id" class="mt-1">
        <div>
          {{ team.teamName }}, {{ team.institutionName }}, {{ team.competitionName }}
        </div>

        <HomeScoreBoard :team="team"/>
      </div>
    </div>

  </div>
</template>

