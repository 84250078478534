import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import './index.css';
import {createPinia} from "pinia";
import {auth0} from "@/services/support/auth0";
import interceptor from "@/services/support/interceptor";
import bugsnag from "@/services/support/bugsnag";

const bugsnagVue = bugsnag();

interceptor();

const app = createApp(App);

if (bugsnagVue != undefined) {
    app.use(bugsnagVue);
}

app.use(router);
app.use(auth0);
app.use(createPinia());
app.mount('#app');
