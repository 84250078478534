import {Ref, ref} from "vue";
import {ITeamList} from "@/services/contracts/ITeamList";
import TeamService from "@/services/TeamService";
import {LoadingState} from "@/services/contracts/LoadingState";
import {defineStore} from "pinia";
import {logError} from "@/services/support/error";

export const useTeamListStore
    = defineStore('teams', () => {

    const EMPTY: ITeamList = {items: []};
    const loadingState = ref(LoadingState.LOADING);
    const teamState: Ref<ITeamList> = ref(EMPTY);

    async function fetch() {

        console.debug('Fetching teams...');
        loadingState.value = LoadingState.LOADING;

        try {
            const response = await TeamService.find();
            teamState.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            teamState.value = EMPTY;
            loadingState.value = LoadingState.ERROR;
            logError('Failed to fetch teams', err);
        }
    }

    return {teamState, loadingState, fetch}
});