<script setup lang="ts">
import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon, ClockIcon} from '@heroicons/vue/24/outline';
import {computed, onMounted, Ref, ref} from "vue";
import {millisecondsToString} from "@/services/support/timeformat";
import MyMathjax from "@/components/MyMathjax.vue";
import {i18n} from "../services/support/i18n";

const props = defineProps<{
  quiz: IQuiz
}>()

const emit = defineEmits<{
  (e: 'validateQuestionButtonClick', selectedChoice: number): void
}>()

const selectedChoice = ref(0);
const timer = ref();
const timerData: Ref<{
  maxResponseTime: number,
  maxPoints: number,
  answerTime: number,
  elapsedTime: number,
  points: number
}> = ref({maxResponseTime: 0, maxPoints: 0, answerTime: 0, elapsedTime: 0, points: 0});

const remainingTime = computed(() => {
  return timerData.value.maxResponseTime - timerData.value.elapsedTime;
})

const remainingTimeString = computed(() => {
  return millisecondsToString(remainingTime.value < 0 ? 0 : remainingTime.value);
})

const progress = computed(() => {
  const x = timerData.value.elapsedTime * 100 / timerData.value.maxResponseTime;
  return "width:" + (100 - (x > 100 ? 100 : x)) + "%";
})

const remainingPoints = computed(() => {
  if (remainingTime.value < 0) {
    return 0;
  }
  if (remainingTime.value > timerData.value.answerTime) {
    return timerData.value.maxPoints;
  }
  return Math.ceil((remainingTime.value / timerData.value.answerTime) * timerData.value.maxPoints);
})

function validateQuestionButtonClick() {
  emit('validateQuestionButtonClick', selectedChoice.value)
}

function timerCountDown() {
  timerData.value.elapsedTime = timerData.value.elapsedTime + 100;
  if (remainingTime.value < -1000) {
    clearInterval(timer.value);
  }
}

onMounted(() => {
console.log("onMounted QuestionDetail");
  if (props.quiz.answer != null) {
    if (props.quiz.answer.maxResponseTime != null) {
      timerData.value.maxResponseTime = props.quiz.answer.maxResponseTime;
    }
    if (props.quiz.answer.maxPoints != null) {
      timerData.value.maxPoints = props.quiz.answer.maxPoints;
    }
    if (props.quiz.answer.answerTime != null) {
      timerData.value.answerTime = props.quiz.answer.answerTime;
    }
    if (props.quiz.answer.elapsedTime != null) {
      timerData.value.elapsedTime = props.quiz.answer.elapsedTime;
    }
    if (props.quiz.answer.points != null) {
      timerData.value.points = props.quiz.answer.points;
    }
  }

  timer.value = setInterval(timerCountDown, 100);
})
</script>

<template>
  <div class="question">

    <MyMathjax/>

    <div class="question-progress-line">
      <div class="icon-box">
        <BoltIcon aria-hidden="true" class="h-6 w-6 bg-orange-500 rounded-full p-1"/>
        <span class="whitespace-nowrap w-10">{{ remainingPoints }} b.</span>
      </div>
      <div class='w-full bg-gray-600 rounded-3xl h-2.5 '>
        <div class="bg-green-600 h-2.5 rounded" :style="progress"></div>
      </div>
      <div class="icon-box">
        <ClockIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
        <span class="whitespace-nowrap w-10">{{ remainingTimeString }}</span>
      </div>
    </div>
    <p>{{ quiz.answer?.questionText }}</p>
    <form>
      <div v-for="choice in quiz.answer?.questionChoices" :key="choice.id" class="block my-3">
        <input type="radio" class="hidden" name="choice" :id="'choice-'+choice.id" :value="choice.id"
               v-model="selectedChoice">
        <label :for="'choice-'+choice.id" class="flex p-3 w-full bg-gray-600 rounded-md">
          <span class="border border-white rounded-full mr-2 w-5 h-5 mt-0.5 flex items-center justify-center shrink-0">
            <span class="block bg-white rounded-full w-3 h-3"></span>
          </span>
          <span>{{ choice.text }}</span>
        </label>
      </div>
    </form>
    <div class="button-line">
      <MyButton @click="validateQuestionButtonClick" :disabled="selectedChoice <= 0">{{ i18n.checkAnswer}}</MyButton>
    </div>
  </div>
</template>
