import api from "@/services/support/api";
import {IInstitutionList} from "@/services/contracts/IInstitutionList";

class InstitutionService {

    find(competitionId: number) {
        return api.get<IInstitutionList>(`institutions/competition/${competitionId}`);
    }
}

export default new InstitutionService();
