import {Ref, ref} from "vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import {IQuiz} from "@/services/contracts/IQuiz";
import QuizService from "@/services/QuizService";
import {IAnswerOutput} from "@/services/contracts/IAnswerOutput";
import {useSelectedQuizStore} from "@/stores/selectedQuiz";
import {logError} from "@/services/support/error";

export const useQuizPseudoStore = () => {
    const loadingState = ref(LoadingState.LOADING);
    const quiz: Ref<IQuiz | null> = ref(null);

    const selectedQuizStore = useSelectedQuizStore();

    function checkSelectedQuiz() {
        if (selectedQuizStore.quiz == null) {
            quiz.value = null;
            loadingState.value = LoadingState.ERROR;
            window.location.href = '/';
        }
    }

    async function fetch() {

        console.debug("Fetching quiz...");
        loadingState.value = LoadingState.LOADING;

        checkSelectedQuiz();

        if (selectedQuizStore.quiz == null) {
            return;
        }

        try {
            const response = await QuizService.getDetail(selectedQuizStore.quiz.id);
            quiz.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            loadingState.value = LoadingState.ERROR;
            logError(`Failed to fetch quiz - quiz: ${selectedQuizStore.quiz.id}`, err);
        }
    }

    async function openQuestion() {

        console.debug('Opening next question...');
        loadingState.value = LoadingState.LOADING;

        checkSelectedQuiz();

        if (selectedQuizStore.quiz == null) {
            return;
        }

        try {
            const response = await QuizService.getQuestion(selectedQuizStore.quiz.id);
            quiz.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            loadingState.value = LoadingState.ERROR;
            logError(`Failed to open next question - quiz: ${selectedQuizStore.quiz.id}`, err);
        }
    }

    async function sendAnswer(selectedChoice: number) {

        console.debug("Sending answer...");
        loadingState.value = LoadingState.LOADING;

        checkSelectedQuiz();

        if (selectedQuizStore.quiz == null) {
            return;
        }

        if (quiz.value == null || quiz.value.answer == null) {
            logError(`Quiz or answer does not exist!`, null);
            return;
        }

        const output: IAnswerOutput = {
            id: quiz.value.answer.id,
            selectedChoiceId: selectedChoice
        }

        try {
            const response = await QuizService.sendAnswer(output);
            quiz.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            loadingState.value = LoadingState.ERROR;
            logError(`Failed to send answer - quiz: ${quiz.value.id}, answer: ${quiz.value?.answer?.id}`, err);
        }
    }

    return {quiz, loadingState, fetch, openQuestion, sendAnswer}
}