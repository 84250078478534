import {i18n} from "@/services/support/i18n";

export enum QuizState {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED'
}

export function niceQuizStateName(state: QuizState): string {
    if (state == QuizState.NEW) {
        return i18n.enums.quizState.new
    }
    if (state == QuizState.IN_PROGRESS) {
        return i18n.enums.quizState.inProgress
    }
    if (state == QuizState.COMPLETED) {
        return i18n.enums.quizState.completed
    }
    if (state == QuizState.EXPIRED) {
        return i18n.enums.quizState.expired
    }
    return i18n.enums.quizState.unknown
}