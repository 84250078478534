<script setup lang="ts">
import {useAuth0} from "@auth0/auth0-vue";
import PageLayout from "@/components/PageLayout.vue";
import {i18n} from "@/services/support/i18n";

const {error} = useAuth0();
</script>

<template>
  <PageLayout v-if="error">
    <h1>{{ i18n.error }}</h1>
    <span>{{ error.message }}</span>
  </PageLayout>
</template>