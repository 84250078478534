<script setup lang="ts">

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {ref, watch} from "vue";

interface Props {
  busy?: boolean,
  disabled?: boolean,
}

const props = withDefaults(defineProps<Props>(),
    {busy: false, disabled: false,});

const busy = ref(props.busy);
const disabled = ref(props.disabled);
const pushed = ref(false);

watch(() => props.busy, () => {
  busy.value = props.busy;
});

watch(() => props.disabled, () => {
  disabled.value = props.disabled;
})

const emit = defineEmits<{
  (e: 'click'): void
}>();

function click() {
  pushed.value = true;
  setTimeout(() => {
    pushed.value = false;
  }, 250);

  emit('click');
}
</script>

<template>
  <div>
    <button type="button" @click="click" :disabled="disabled || pushed || busy">
      <div class="text-center relative">
        <slot></slot>
        <LoadingSpinner :show="busy" class="text-cez-color absolute right-1 bottom-1/4"/>
      </div>
    </button>
  </div>
</template>

<style scoped>
button {
  @apply block w-full rounded-md p-2
  text-center text-sm font-semibold text-white
  bg-gradient-to-br from-cez-color to-rose-700
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600
}

button:hover {
  @apply from-orange-500 to-rose-500
}

button:disabled {
  @apply from-orange-800 to-rose-800
  text-gray-500
}
</style>