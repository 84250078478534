<script setup lang="ts">
import HomeProfileBanner from "@/components/HomeProfileBanner.vue";
import HomeQuizList from "@/components/HomeQuizList.vue";
import {useVersionStore} from "@/stores/version";
import HomeTeamList from "@/components/HomeTeamList.vue";
import {onMounted} from "vue";
import {useExoUserStore} from "@/stores/exouser";

const versionStore = useVersionStore();
const exoUserStore = useExoUserStore();
onMounted(() => versionStore.check(true));
</script>

<template>
  <HomeProfileBanner/>
  <HomeTeamList v-if="exoUserStore.isProfileCompleted"/>
  <HomeQuizList v-if="exoUserStore.isProfileCompleted"/>
</template>

