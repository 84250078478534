<script setup lang="ts">
import {ref, watch} from "vue";

interface Props {
  show?: boolean,
}

const props = withDefaults(defineProps<Props>(),
    {show: false});

const visible = ref(props.show);

watch(() => props.show, () => {
  if (props.show) {
    setTimeout(() => {
      visible.value = props.show;
    }, 500)
  } else {
    visible.value = false;
  }
});

</script>

<template>
  <div class="loading-state" ref="spinner" v-show="visible">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100"
         fill="currentColor" stroke="currentColor" stroke-width="15">
      <rect width="30" height="30" x="25" y="50">
        <animate attributeName="y" calcMode="spline" dur="2" values="25;60;25;" keySplines=".5 0 .5 1;.5 0 .5 1"
                 repeatCount="indefinite" begin="-.4"></animate>
      </rect>
      <rect width="30" height="30" x="85" y="50">
        <animate attributeName="y" calcMode="spline" dur="2" values="25;60;25;" keySplines=".5 0 .5 1;.5 0 .5 1"
                 repeatCount="indefinite" begin="-.2"></animate>
      </rect>
      <rect width="30" height="30" x="145" y="50">
        <animate attributeName="y" calcMode="spline" dur="2" values="25;60;25;" keySplines=".5 0 .5 1;.5 0 .5 1"
                 repeatCount="indefinite" begin="0"></animate>
      </rect>
    </svg>
  </div>
</template>

<style scoped>
.loading-state, .loading-state svg {
  width: 1rem;
  height: 0.5rem;
}
</style>

