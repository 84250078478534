<script setup lang="ts">

import MyInput from "@/components/MyInput.vue";
import {ArrowUpCircleIcon} from "@heroicons/vue/24/outline";
import MyButton from "@/components/MyButton.vue";
import {useExoUserStore} from "@/stores/exouser";
import {computed, onMounted, Ref, ref} from "vue";
import {IExoUser} from "@/services/contracts/IExoUser";
import {useNotificationStore} from "@/stores/notification";
import {i18n} from "@/services/support/i18n";

const exoUserStore = useExoUserStore();
const notificationStore = useNotificationStore();

const changed = ref(false);
const imageChanged = ref(false);
const busy = ref(false);
const invalid = computed(() => {
  return exoUser.value.firstName == ''
      || exoUser.value.lastName == ''
      || exoUser.value.nickname == '';
});
const image = ref();
const fileInput = ref();
const preferredEmail = ref();
const firstName = ref();
const lastName = ref();
const nickname = ref();

const exoUser: Ref<IExoUser> = ref({
  id: 0,
  ssoId: '',
  firstName: '',
  lastName: '',
  nickname: '',
  preferredEmail: '',
  profileImageData: undefined,
  ssoUsername: null,
  email: null,
});

const accountName = computed(() => {
  if (exoUserStore.exoUser.ssoId.startsWith('google')) {
    return 'Google ' + exoUserStore.exoUser.ssoUsername;
  } else if (exoUserStore.exoUser.ssoId.startsWith('apple')) {
    return 'Apple ' + exoUserStore.exoUser.ssoUsername;
  }
  return exoUserStore.exoUser.ssoUsername;
})

onMounted(() => {

  exoUserStore.silentFetch()?.finally(() => {

    const src = exoUserStore.exoUser;
    const dst = exoUser.value;

    dst.id = src.id;
    dst.ssoId = src.ssoId;
    dst.ssoUsername = src.ssoUsername;

    dst.firstName = src.firstName;
    dst.lastName = src.lastName;
    dst.nickname = src.nickname;

    dst.preferredEmail = src.preferredEmail;
    dst.email = src.email;
    dst.profileImageData = src.profileImageData ? src.profileImageData : "/img/logo.png";

    changed.value = false;
    imageChanged.value = false;

    checkFirstName();
    checkLastName();
    checkNickname();
  });
});

async function saveChanges() {

  const eu: IExoUser = {
    id: exoUser.value.id,
    ssoId: exoUser.value.ssoId,
    firstName: exoUser.value.firstName,
    lastName: exoUser.value.lastName,
    nickname: exoUser.value.nickname,
    preferredEmail: exoUser.value.preferredEmail,
    profileImageData: imageChanged.value ? exoUser.value.profileImageData : undefined,
    ssoUsername: null,
    email: null,
  };

  busy.value = true;

  try {
    await exoUserStore.save(eu);

    changed.value = false;
    imageChanged.value = false;
  } catch (error) {
    notificationStore.show(i18n.error, i18n.failedToSaveChanges);
  } finally {
    busy.value = false;
  }
}

function cropAndResizeImage(imgElement: HTMLImageElement, outputSize = 256): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.width = outputSize;
  canvas.height = outputSize;

  const imgWidth = imgElement.naturalWidth;
  const imgHeight = imgElement.naturalHeight;

  const cropSize = Math.min(imgWidth, imgHeight);
  const cropX = (imgWidth - cropSize) / 2;
  const cropY = (imgHeight - cropSize) / 2;

  ctx.drawImage(imgElement, cropX, cropY, cropSize, cropSize, 0, 0, outputSize, outputSize);

  return canvas.toDataURL();
}

function loadFromFile(ev: any) {
  const files = ev.target.files;

  if (files && files.length) {
    const reader = new FileReader();

    reader.onload = function () {
      image.value.title = files[0].name;

      const tmpImg = document.createElement('img') as HTMLImageElement;

      tmpImg.onload = function () {
        exoUser.value.profileImageData = cropAndResizeImage(tmpImg);
        changed.value = true;
        imageChanged.value = true;
      }

      tmpImg.src = reader.result as string;
    }

    reader.readAsDataURL(files[0]);
  }
}

function checkFirstName(e = undefined) {
  if (exoUser.value.firstName == '') {
    firstName.value.setValid(false, i18n.required);
  } else {
    firstName.value.setValid(true, '');
    if (e != undefined) {
      changed.value = true;
    }
  }
}

function checkLastName(e = undefined) {
  if (exoUser.value.lastName == '') {
    lastName.value.setValid(false, i18n.required);
  } else {
    lastName.value.setValid(true, '');
    if (e != undefined) {
      changed.value = true;
    }
  }
}

function checkNickname(e = undefined) {
  if (exoUser.value.nickname == '') {
    nickname.value.setValid(false, i18n.required);
  } else {
    nickname.value.setValid(true, '');
    if (e != undefined) {
      changed.value = true;
    }
  }
}
</script>

<template>
  <div class="profile-unit">
    <div class="pb-1 flex flex-col items-center relative">
      <img ref="image"
           class="inline-block rounded-full border-cez-color border-2 profile-image"
           :src="exoUser.profileImageData"
           alt=""/>
      <input @change="loadFromFile" ref="fileInput" type="file" hidden/>
      <div class="w-10 h-10 bg-cez-color rounded-full cursor-pointer text-white"
           style="margin-top: -50px; margin-left: 160px;"
           :title="i18n.loadImage" @click="fileInput.click()">
        <ArrowUpCircleIcon/>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-y-2 ">
      <MyInput :label="i18n.account" disabled type="text"
               v-model="accountName" name="ssoUsername"/>

      <MyInput :label="i18n.email" disabled type="text" ref="preferredEmail"
               v-model="exoUser.preferredEmail" name="preferredEmail"/>

      <MyInput :label="i18n.firstName" type="text" ref="firstName"
               @input="checkFirstName"
               v-model="exoUser.firstName" name="firstName"/>

      <MyInput :label="i18n.lastName" type="text" ref="lastName"
               @input="checkLastName"
               v-model="exoUser.lastName" name="lastName"/>

      <MyInput :label="i18n.nickname" type="text" ref="nickname"
               @input="checkNickname"
               v-model="exoUser.nickname" name="nickname"/>
    </div>

    <div class="pt-4">
      <MyButton ref="save" @click="saveChanges" :busy="busy" :disabled="invalid || !changed">{{ i18n.save }}</MyButton>
    </div>
  </div>
</template>

<style scoped>
.profile-image {
  width: 256px;
  height: 256px;
}
</style>