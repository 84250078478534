<script setup lang="ts">
import PageLayout from "@/components/PageLayout.vue";
import {useVersionStore} from "@/stores/version";
import {computed, onMounted, watch} from "vue";
import {i18n} from "@/services/support/i18n";
import ScoreLine from "@/components/ScoreLine.vue";
import CompetitionCombo from "@/components/CompetitionCombo.vue";
import InstitutionCombo from "@/components/InstitutionCombo.vue";
import {useCompetitionListStore} from "@/stores/competitionList";
import {useTeamListStore} from "@/stores/teamList";
import LoadingError from "@/components/LoadingError.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {useScoreStore} from "@/stores/score";

const scoreStore = useScoreStore();
const versionStore = useVersionStore();
const competitionStore = useCompetitionListStore();
const teamListStore = useTeamListStore();

onMounted(async () => {
  await Promise.all([versionStore.check(true), scoreStore.onMounted()]);
});

watch(() => scoreStore.institution, () => {
  scoreStore.fetch();
});

const isLoading = computed(() => {
  return scoreStore.loadingState == LoadingState.LOADING
      || teamListStore.loadingState == LoadingState.LOADING
      || competitionStore.loadingState == LoadingState.LOADING;
})
</script>

<template>
  <PageLayout>
    <h1>{{ i18n.scoreboard }}</h1>

    <label class="block text-xs font-medium leading-6 text-gray-400">v soutěži</label>
    <CompetitionCombo/>

    <label class="block text-xs font-medium leading-6 text-gray-400 pt-2">v rámci</label>
    <InstitutionCombo/>

    <LoadingIndicator :show="isLoading && scoreStore.list.items.length == 0" class="pt-2"/>
    <LoadingError v-if="scoreStore.loadingState == LoadingState.ERROR"/>

    <div class="pt-3" v-if="scoreStore.loadingState != LoadingState.ERROR">
      <p v-if="scoreStore.list.items.length < 1 && scoreStore.loadingState == LoadingState.OK">
        {{ i18n.noScoresAvailable }}</p>
      <ScoreLine v-for="line in scoreStore.list.items" :key="line.idExoUser" :data="line"/>
    </div>
  </PageLayout>
</template>
