import {getEnv} from "@/services/support/runtime";
import Bugsnag from "@bugsnag/js";

export function logError(msg: string, err: any) {

    console.error(msg, err);

    if (getEnv('BUGSNAG_ENABLED') == 'true') {
        Bugsnag.notify(new Error(err), (event) => {
            event.context = msg;
            event.severity = 'error'
        });
    }
}