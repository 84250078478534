<template>
  <MyButton @click="handleLogout" class="from-red-600 to-red-900">{{ i18n.logout }}</MyButton>
</template>

<script setup>
import {useAuth0} from "@auth0/auth0-vue";
import MyButton from "@/components/MyButton.vue";
import {i18n} from "../services/support/i18n";

const {logout} = useAuth0();

const handleLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
</script>

<style scoped>
button {
  @apply bg-red-700
}
</style>/