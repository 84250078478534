export const i18n = {
    scorePoints: 'Bodů celkem',
    completedQuizzes: 'Dokončených kvízů',
    correctAnswers: 'Správných odpovědí',
    competitionRank: 'Pořadí',

    noScoresAvailable: 'Zatím není nic k hodnocení',

    quizzes: 'Kvízy',
    noQuizzesAvailable: 'Momentálně pro tebe nemáme žádný kvíz...',

    team: 'Ročník/třída',
    notATeamMember: 'Nejsi členem žádné třídy/ročníku!',
    teams: 'Členství v třídách/ročnících',
    joinTeam: 'Připojit se k ročníku/třídě',
    leaveTeam: 'Opustit',
    accessCode: 'Kód ročníku/třídy',
    failedToLeaveTeam: 'Nepodařilo se opustit tým',
    failedToJoinTeamWithCode: 'Nepodařilo se připojit k týmu s kódem',

    profileIncomplete: 'Nemáš vyplněné všechny potřebné položky profilu',
    completeProfile: 'Doplnit profil',

    exoUserLoadingFailed: 'Chyba při načítání uživatelkých dat',

    termsAndConditions: 'Pravidla a podmínky',
    gdpr: 'GDPR',
    contactCez: 'Kontaktuj ČEZ',

    loadingError: 'Chyba při načítání dat',
    required: 'Povinné',
    logout: 'Odhlásit',

    loadImage: 'Nahrát obrázek',
    account: 'Účet',
    email: 'Email',
    firstName: 'Jméno',
    lastName: 'Příjmení',
    nickname: 'Přezdívka',
    save: 'Uložit',
    error: 'Chyba',
    failedToSaveChanges: 'Nepodařilo se uložit změny!',
    notFound: 'Nenalezeno',
    scoreboard: 'Průběžné výsledky',

    state: 'Stav',
    deadline: 'Uzávěrka',
    totalTime: 'Celkový čas',
    achievedPoints: 'Získáno bodů',

    runQuiz: 'Spustit kvíz',
    browseQuiz: 'Prohlížet kvíz',

    checkAnswer: 'Zkontrolovat odpověď',
    nextQuestion: 'Další otázka',
    correct: 'Správně!',
    incorrect: 'Chybička',

    school: 'Škola',
    competition: 'Soutěž',

    enums: {
        quizState: {
            new: 'Nový',
            inProgress: 'Rozpracovný',
            completed: 'Dokončený',
            expired: 'Expirovaný',
            unknown: 'Neznámý',
        }
    },
}
