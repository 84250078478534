<script setup lang="ts">
import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon} from '@heroicons/vue/24/outline';
import {i18n} from "@/services/support/i18n";

defineProps<{ quiz: IQuiz }>();

const emit = defineEmits<{
  (e: 'nextQuestionButtonClick'): void
}>()

function nextQuestionButtonClick() {
  emit('nextQuestionButtonClick')
}
</script>

<template>
  <div class="question-result">
    <div class="flex flex-col items-center gap-3">
      <p v-if="quiz.answer?.correct">{{ i18n.correct }}</p>
      <p v-if="!quiz.answer?.correct">{{ i18n.incorrect }}</p>
      <img v-if="quiz.answer?.correct" class="grow-0" src="img/correct.png" alt="Správně">
      <img v-if="!quiz.answer?.correct" class="grow-0" src="img/wrong.png" alt="Chyba">
      <div class="icon-box">
        <BoltIcon aria-hidden="true" class="h-6 w-6 bg-orange-500 rounded-full p-1"/>
        <span class="whitespace-nowrap">+ {{ quiz.answer?.points }} b.</span>
      </div>
    </div>
    <div class="button-line">
      <MyButton @click="nextQuestionButtonClick">{{ i18n.nextQuestion }}</MyButton>
    </div>
  </div>
</template>
