<script setup lang="ts">
import {onMounted} from "vue";
import {useQuizPseudoStore} from "@/stores/quiz";
import {ArrowLeftIcon} from '@heroicons/vue/24/outline';
import {QuizPageState} from "@/services/contracts/QuizPageState";
import QuestionResult from "@/components/QuestionResult.vue";
import QuestionDetail from "@/components/QuestionDetail.vue";
import QuizDetail from "@/components/QuizDetail.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {i18n} from "@/services/support/i18n";

const quizStore = useQuizPseudoStore();

function showQuestionButtonClick() {
  quizStore.openQuestion();
}

function validateQuestionButtonClick(selectedChoice: number) {
  quizStore.sendAnswer(selectedChoice);
}

function nextQuestionButtonClick() {
  showQuestionButtonClick();
}

onMounted(() => {
  quizStore.fetch();
})
</script>

<template>
  <div class="quiz-header">
    <router-link to="/">
      <ArrowLeftIcon class="w-6 h-6"/>
    </router-link>
    <div class="h-10 w-10 p-1 bg-orange-500 rounded-full text-sm flex items-center justify-center">
      <span>{{ quizStore.quiz.value?.answerCount }}/{{ quizStore.quiz.value?.questionCount }}</span>
    </div>
    <div>{{ quizStore.quiz.value?.roundName }}, {{ quizStore.quiz.value?.competitionName }}</div>
  </div>

  <LoadingIndicator ref="loading" :show-text="true" :show="quizStore.loadingState.value == LoadingState.LOADING"/>

  <div v-if="quizStore.loadingState.value == LoadingState.ERROR">
    <p>{{ i18n.loadingError }}</p>
  </div>

  <div v-if="quizStore.loadingState.value == LoadingState.OK">
    <QuizDetail v-if="quizStore.quiz.value?.pageState == QuizPageState.INFO"
                :quiz="quizStore.quiz.value"
                @showQuestionButtonClick="showQuestionButtonClick"/>

    <QuestionDetail v-if="quizStore.quiz.value?.pageState == QuizPageState.QUESTION"
                    :quiz="quizStore.quiz.value"
                    @validateQuestionButtonClick="validateQuestionButtonClick"/>

    <QuestionResult v-if="quizStore.quiz.value?.pageState == QuizPageState.QUESTION_RESULT"
                    :quiz="quizStore.quiz.value"
                    @nextQuestionButtonClick="nextQuestionButtonClick"/>

  </div>
</template>