import {getEnv} from "@/services/support/runtime";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import BugsnagPerformance from "@bugsnag/browser-performance";
import {auth0} from "@/services/support/auth0";

const setup = () => {

    if (getEnv('BUGSNAG_ENABLED') == 'true') {
        Bugsnag.start({
            appVersion: getEnv('VERSION'),
            apiKey: getEnv('BUGSNAG_KEY'),
            releaseStage: getEnv('BUGSNAG_RELEASE_STAGE'),
            plugins: [new BugsnagPluginVue()],
            onError: function (event) {
                const user = auth0.user.value;
                event.setUser(user?.sub, user?.email, user?.name);
            }
        });

        BugsnagPerformance.start({
            apiKey: getEnv('BUGSNAG_KEY'),
            appVersion: getEnv('VERSION'),
        });

        return Bugsnag.getPlugin('vue')
    } else {
        console.warn('BugSnag is disabled');
    }

    return null;
};

export default setup;
