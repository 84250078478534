import api from "@/services/support/api";
import {ITeamList} from "@/services/contracts/ITeamList";

class TeamService {

    async find() {
        return api.get<ITeamList>('teams');
    }

    join(accessCode: string) {
        return api.post<ITeamList>('teams/join', {accessCode: accessCode});
    }

    leave(teamId: number) {
        return api.post<ITeamList>(`teams/leave/${teamId}`);
    }
}

export default new TeamService();
