<script setup lang="ts">
import {ref, watch} from "vue";
import {LoadingState} from "@/services/contracts/LoadingState";

interface Props {
  show?: boolean,
  showText?: boolean,
  text?: string | LoadingState
}

const props = withDefaults(defineProps<Props>(),
    {show: false, showText: false, text: ""});

const visible = ref(props.show);

watch(() => props.show, () => {
  if (props.show) {
    setTimeout(() => {
      visible.value = props.show;
    }, 200)
  } else {
    visible.value = false;
  }
});

</script>

<template>
  <div v-show="visible" class="loading-indicator">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 80">
      <rect fill="#F24F00" stroke="#F24F00" stroke-width="15" width="30" height="30" x="25" y="25">
        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1"
                 repeatCount="indefinite" begin="-.4"></animate>
      </rect>
      <rect fill="#F24F00" stroke="#F24F00" stroke-width="15" width="30" height="30" x="85" y="25">
        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1"
                 repeatCount="indefinite" begin="-.2"></animate>
      </rect>
      <rect fill="#F24F00" stroke="#F24F00" stroke-width="15" width="30" height="30" x="145" y="25">
        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1"
                 repeatCount="indefinite" begin="0"></animate>
      </rect>
    </svg>
    <span v-if="showText" class="text-sm">{{ text }}</span>
  </div>
</template>

<style scoped>
.loading-indicator {
  @apply flex flex-col justify-center items-center w-full h-full
}

.loading-indicator svg {
  @apply max-w-20;
}
</style>