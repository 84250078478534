<script setup lang="ts">

import {useExoUserStore} from "@/stores/exouser";
import MyButton from "@/components/MyButton.vue";
import {i18n} from "@/services/support/i18n";

const exoUserStore = useExoUserStore();

</script>

<template>
  <router-link to="/profile">
    <div class="flex items-center pt-5 pb-3">
      <img class="inline-block h-12 w-12 rounded-full border-orange-500 border-2"
           :src="exoUserStore.exoUser.profileImageData == null ? '/img/logo.png' : exoUserStore.exoUser.profileImageData"
           alt=""/>
      <div class="pl-3">
        <p>{{ exoUserStore.fullName }}</p>
        <p class="text-sm text-gray-500">{{ exoUserStore.exoUser.nickname }}</p>
      </div>
    </div>
    <div v-if="!exoUserStore.isProfileCompleted">
      <div class="mb-1">{{ i18n.profileIncomplete}}</div>
      <p></p>
      <MyButton>{{ i18n.completeProfile}}</MyButton>
    </div>
  </router-link>
</template>

<style scoped>

</style>