import api from "@/services/support/api";
import {ICompetitionList} from "@/services/contracts/ICompetitionList";

class CompetitionService {

    find() {
        return api.get<ICompetitionList>('competitions');
    }
}

export default new CompetitionService();
