import {createRouter, createWebHistory} from "vue-router";
import HomePage from "./pages/HomePage.vue";

import {authGuard} from "@auth0/auth0-vue";
import CallbackPage from "@/pages/CallbackPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import LogoutPage from "@/pages/LogoutPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import ScorePage from "@/pages/ScorePage.vue";
import QuizPage from "@/pages/QuizPage.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomePage,
        beforeEnter: authGuard,
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfilePage,
        beforeEnter: authGuard,
    },
    {
        path: "/score",
        name: "score",
        component: ScorePage,
        beforeEnter: authGuard,
    },
    {
        path: "/quiz",
        name: "quiz",
        component: QuizPage,
        beforeEnter: authGuard,
    },
    {
        path: '/index.html',
        redirect: '/'
    },
    {
        path: "/callback",
        name: "callback",
        component: CallbackPage,
    },
    {
        path: "/logout",
        name: "logout",
        component: LogoutPage,
    },
    {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: NotFoundPage,
    },
];

// base: process.env.BASE_URL
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }
    }
});

export default router;