<script setup lang="ts">
import {useVersionStore} from "@/stores/version";
import PageLayout from "@/components/PageLayout.vue";
import LogoutButton from "@/components/LogoutButton.vue";
import ProfileInfoLinks from "@/components/ProfileInfoLinks.vue";
import ProfileAccount from "@/components/ProfileAccount.vue";
import ProfileTeams from "@/components/ProfileTeams.vue";
import {onMounted} from "vue";

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));
</script>

<template>
  <PageLayout>
    <ProfileAccount/>
    <ProfileTeams/>
    <ProfileInfoLinks/>

    <div class="profile-unit">
      <LogoutButton/>
      <div class="text-gray-600 text-sm text-center pt-4">{{ versionStore.version }}</div>
    </div>

  </PageLayout>
</template>
