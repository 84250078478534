import api from "@/services/support/api";
import {IQuiz} from "@/services/contracts/IQuiz";
import {IAnswerOutput} from "@/services/contracts/IAnswerOutput";
import {IAnswer} from "./contracts/IAnswer";

class QuizService {

    getDetail(quizId: number) {
        return api.get<IQuiz>('quizzes/detail/' + quizId);
    }

    getQuestion(quizId: number) {
        return api.get<IQuiz>('quizzes/question/' + quizId);
    }

    sendAnswer(output: IAnswerOutput) {
        return api.post<IQuiz>('quizzes/answer', output);
    }

    getAnsweredQuestion(quizId: number) {
        return api.get<Array<IAnswer>>('quizzes/answered-questions/' + quizId);
    }

}

export default new QuizService();
