<script setup lang="ts">
import {IAnswer} from "@/services/contracts/IAnswer";
import {BeakerIcon, BoltIcon, CheckIcon, ClockIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import {computed} from "vue";
import {millisecondsToString} from "@/services/support/timeformat";

const props = defineProps<{
  answer: IAnswer,
  answerCount: number
  answerPosition: number
}>()

const elapsedTimeAsString = computed(() => {
  if (props.answer.elapsedTime != null) {
    return millisecondsToString(props.answer.elapsedTime)
  }
  return millisecondsToString(props.answer.maxResponseTime);
})
</script>

<template>
  <div class="answeredQuestion border-t border-gray-700 mt-5 py-5">

    <div class="icon-box">
      <BeakerIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      {{ answerPosition + 1}} / {{ answerCount }}
    </div>

    <div class="flex justify-between">
      <div class="icon-box">
        <BoltIcon aria-hidden="true" class="h-6 w-6 bg-orange-500 rounded-full p-1"/>
        <span class="whitespace-nowrap w-10">{{ answer.points }} / {{ answer.maxPoints }} b.</span>
      </div>
      <div class="icon-box">
        <ClockIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
        <span class="whitespace-nowrap w-10">{{ elapsedTimeAsString }}</span>
      </div>
    </div>

    <div v-for="image in answer.embeddedImages" :key="image" class="py-1">
      <img :src="image" class="max-w-full"/>
    </div>

    <p>{{ answer.questionText }}</p>

    <div v-for="choice in answer.questionChoices" :key="choice.id" class="block my-3 radio"
         :class="choice.id == answer.selectedChoiceId ? (answer.correct ? 'correct ': '') + 'selected' : ''">
      <label :for="'choice-'+choice.id" class="flex p-3 w-full bg-gray-600 rounded-md justify-between">
        <span class="border border-white rounded-full mr-2 w-5 h-5 mt-0.5 flex items-center justify-center shrink-0">
          <span class="block bg-white rounded-full w-3 h-3"></span>
        </span>
        <span class="w-full">{{ choice.text }}</span>
        <CheckIcon v-if="choice.id == answer.selectedChoiceId && answer.correct" class="h-6 w-6"/>
        <XMarkIcon v-if="choice.id == answer.selectedChoiceId && !answer.correct" class="h-6 w-6"/>
      </label>
    </div>
  </div>
</template>
