<script setup lang="ts">
import {BeakerIcon, BoltIcon} from '@heroicons/vue/24/outline'

import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingError from "@/components/LoadingError.vue";
import {niceQuizStateName, QuizState} from "@/services/contracts/QuizState";
import {i18n} from "@/services/support/i18n";
import {onMounted} from "vue";
import {useQuizListStore} from "@/stores/quizList";
import {useSelectedQuizStore} from "@/stores/selectedQuiz";
import {IQuizListItem} from "@/services/contracts/IQuizListItem";

const quizListStore = useQuizListStore();
const selectedQuizStore = useSelectedQuizStore();

onMounted(() => {
  quizListStore.fetch();
})

function quizClick(quiz: IQuizListItem) {
  selectedQuizStore.set(quiz)
}

</script>

<template>
  <div class="pb-3">
    <h1>{{ i18n.quizzes }}</h1>
    <LoadingIndicator :show-text="true" :show="quizListStore.loadingState == LoadingState.LOADING"/>

    <div v-if="quizListStore.loadingState == LoadingState.OK">
      <p v-if="quizListStore.quizList.length < 1">{{ i18n.noQuizzesAvailable }}</p>
      <router-link v-for="quiz in quizListStore.quizList"
                   to="/quiz"
                   :key="quiz.id"
                   @click="quizClick(quiz)"
                   class="quiz-box"
                   :class="quiz.state == QuizState.COMPLETED || quiz.state == QuizState.EXPIRED ? 'finished' : ''">
        <div class="head-part">
          <div>{{ quiz.roundName }}, {{ quiz.competitionName }}</div>
          <div class="text-xs">{{ quiz.deadline }}</div>
          {{ niceQuizStateName(quiz.state) }}
        </div>
        <div class="footer-part">
          <div class="icon-box">
            <BeakerIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
            {{ quiz.answerCount }} / {{ quiz.questionCount }}
          </div>
          <div class="icon-box">
            <BoltIcon aria-hidden="true" class="h-7 w-7 bg-orange-500 rounded-full p-1"/>
            {{ quiz.achievedPoints }} / {{ quiz.maxPoints }}
          </div>
        </div>
      </router-link>
    </div>

    <LoadingError v-if="quizListStore.loadingState == LoadingState.ERROR"/>

  </div>
</template>

<style scoped>

</style>