<script setup lang="ts">

import NotificationPopup from "@/components/NotificationPopup.vue";
import {useExoUserStore} from "@/stores/exouser";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import LoadingError from "@/components/LoadingError.vue";
import {i18n} from "@/services/support/i18n";

const exoUserStore = useExoUserStore();
</script>

<template>
  <router-view v-if="exoUserStore.loadingState == LoadingState.OK"/>
  <LoadingIndicator :show="exoUserStore.loadingState == LoadingState.LOADING"/>
  <LoadingError v-if="exoUserStore.loadingState == LoadingState.ERROR"
                :show-text="true"
                :text="i18n.exoUserLoadingFailed"
                class="mt-5"/>

  <NotificationPopup/>
</template>

<style scoped>

</style>