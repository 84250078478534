import {computed, Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IQuizListItem} from "@/services/contracts/IQuizListItem";
import quizListItemService from "@/services/QuizListItemService";

export const useSelectedQuizStore
    = defineStore('selectedQuiz', () => {

    const state: Ref<IQuizListItem | null> = ref(null);

    const quiz = computed(() => {
        return state.value;
    })

    function set(value: IQuizListItem | null) {
        quizListItemService.set(value);
        state.value = value;
    }

    state.value = quizListItemService.get();

    return {quiz, set}
})
