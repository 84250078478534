<script setup lang="ts">
import {mathjax} from "mathjax-full/js/mathjax.js";
//import {TeX} from "mathjax-full/js/input/tex.js";
//import {CHTML} from "mathjax-full/js/output/chtml.js";
import {SVG} from 'mathjax-full/js/output/svg'
import {browserAdaptor} from "mathjax-full/js/adaptors/browserAdaptor.js";
import {AsciiMath} from "mathjax-full/js/input/asciimath.js"
// Register the HTML document handler
import {RegisterHTMLHandler} from "mathjax-full/js/handlers/html.js";
import {onMounted} from "vue";

RegisterHTMLHandler(browserAdaptor());

const html = mathjax.document(document, {
  InputJax: [
    new AsciiMath({
      delimiters: [['`', '`'], ['$', '$'], ['\\(', '\\)']],
    }),
    // new TeX({
    //   inlineMath: [['$', '$'], ['\\(', '\\)']],
    //   packages: ['base', 'ams', 'noundefined', 'newcommand', 'boldsymbol']
    // })
  ],
  OutputJax: new SVG({})
});

onMounted(() => {
  console.log("Mathjax mounted");
  html.findMath()
      .compile()
      .getMetrics()
      .typeset()
      .updateDocument()
})

</script>
<template>
  <span></span>
</template>
