import {Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IQuizListItem} from "@/services/contracts/IQuizListItem";
import QuizListItemService from "@/services/QuizListItemService";
import {LoadingState} from "@/services/contracts/LoadingState";
import {logError} from "@/services/support/error";

export const useQuizListStore = defineStore('quizList', () => {
    const loadingState = ref(LoadingState.LOADING);
    const quizList: Ref<Array<IQuizListItem>> = ref([]);

    async function fetch() {

        console.debug('Fetching available quizzes...');
        loadingState.value = LoadingState.LOADING;

        try {
            const response = await QuizListItemService.findAvailable();
            quizList.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            loadingState.value = LoadingState.ERROR;
            logError('Failed to fetch available quizzes', err);
        }
    }

    return {quizList, loadingState, fetch}
})
