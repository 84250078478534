<script setup lang="ts">
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/vue/20/solid";
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/vue";
import {useCompetitionListStore} from "@/stores/competitionList";
import {useScoreStore} from "@/stores/score";

const competitionListStore = useCompetitionListStore();
const scoreStore = useScoreStore();
</script>

<template>
  <Listbox as="div" v-model="scoreStore.competition">
    <div class="relative mt-0.5">
      <ListboxButton
          class="relative w-full cursor-default rounded-md bg-gray-700 text-white
           py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-600
          focus:outline-none focus:ring-2 focus:ring-cez-color leading-6">
        <span class="block truncate">{{
            scoreStore.competition == null ? "&nbsp;" : scoreStore.competition.competitionName
          }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400"/>
          </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <ListboxOptions
            class="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md bg-gray-600 py-1
            text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ListboxOption as="template"
                         v-for="item in competitionListStore.list.items" :key="item.id" :value="item"
                         v-slot="{ active, selected }">

            <li class="relative cursor-default select-none py-2 pl-8 pr-4"
                :class="active ? 'bg-cez-color text-white' : 'text-gray-white'">
              <span class="block truncate" >{{ item.competitionName }}</span>
              <span v-if="selected"
                    :class="active ? 'text-white' : 'text-cez-color'"
                    class="absolute inset-y-0 left-0 flex items-center pl-1.5">
                  <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                </span>
            </li>

          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<style scoped>

</style>