import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import VersionService from "@/services/VersionService";
import {logError} from "@/services/support/error";

export const useVersionStore
    = defineStore('version', () => {

    const versionChanged: Ref<boolean> = ref(false);
    const version: Ref<string> = ref('');

    async function check(forceReload = false) {

        console.debug('Fetching version...');

        try {
            const response = await VersionService.findVersion();
            const local = VersionService.get();
            const remote = response.data;

            if (local != remote) {

                version.value = remote;

                if (forceReload) {
                    console.warn(`Upgrading application ${local} -> ${remote}`);
                    VersionService.set(version.value);
                    location.reload();
                } else {
                    versionChanged.value = true;
                }
            } else {
                version.value = local;
                versionChanged.value = false;
            }
        } catch (err) {
            logError('Failed to fetch version', err);
        }
    }

    return {version, versionChanged, check}
})