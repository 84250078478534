import api from "@/services/support/api";
import {IScoreList} from "@/services/contracts/IScoreList";

class ScoreService {

    find(competitionId: number, institutionId: number) {
        return api.get<IScoreList>(`scores/competition/${competitionId}/institution/${institutionId}`, {});
    }
}

export default new ScoreService();
