import {Ref, ref} from "vue";
import {defineStore} from "pinia";
import {INotification} from "@/services/contracts/INotification";

export const useNotificationStore
    = defineStore('notification', () => {

    const counter = ref(0);
    const items: Ref<Array<INotification>> = ref([]);

    function show(_title: string, _message: string) {
        const item: INotification = {id: counter.value++, title: _title, message: _message, visible: true};
        items.value.push(item);

        setTimeout(() => {
            hide(item);
        }, 5000);
    }

    function hide(selected: INotification) {

        const found = items.value.find(item => item.id == selected.id);
        if (found) {
            found.visible = false;

            setTimeout(() => {
                items.value = items.value.filter(item => item.id != selected.id);
            }, 200);
        }
    }

    return {items, show, hide};
})