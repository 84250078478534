import {ref} from "vue";
import TeamService from "@/services/TeamService";
import {useNotificationStore} from "@/stores/notification";
import {ITeam} from "@/services/contracts/ITeam";
import {useTeamListStore} from "@/stores/teamList";
import {i18n} from "@/services/support/i18n";
import {logError} from "@/services/support/error";

export const useTeamStore
    = () => {

    const joining = ref(false);
    const leaving = ref(false);

    const teamListStore = useTeamListStore();
    const notificationStore = useNotificationStore();

    async function joinTeam(accessCode: string) {
        joining.value = true
        try {
            const response = await TeamService.join(accessCode);
            teamListStore.teamState = response.data;
        } catch (err) {
            notificationStore.show(i18n.error, i18n.failedToJoinTeamWithCode + ' "' + accessCode + '"');
            logError('Failed to join team ' + accessCode, err);
        } finally {
            joining.value = false;
        }
    }

    async function leaveTeam(team: ITeam) {
        leaving.value = true
        try {
            const response = await TeamService.leave(team.id);
            teamListStore.teamState = response.data;
        } catch (err) {
            notificationStore.show(i18n.error, i18n.failedToLeaveTeam + ' "' + team.teamName + '"');
            logError('Failed to leave team ' + team.teamName, err);
        } finally {
            leaving.value = false;
        }
    }

    return {isJoiningBusy: joining, isLeavingBusy: leaving, joinTeam, leaveTeam}
};